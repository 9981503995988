.main {
display: flex;
align-items: center;
justify-content: center;
}

.babyImage {
    width: 96%;
   max-width: 1000px;
   flex: 1;
}
