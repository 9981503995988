.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 40px;
}

@media screen and (max-width : 590px) {
    .header {
        flex-direction: column;
        padding: 20px 10px;
        gap: 10px;
    }
}

.header img {
    height: 50px;
    width: auto;
}

.app {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.app img {
    height: 40px;
    width: auto;
}