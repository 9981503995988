/* PrivacyPolicy.module.css */
.PrivacyPolicy {
    padding: 20px;
    max-width: 1000px;
    margin: auto;
    font-family: 'Gotham', sans-serif;
}

.PrivacyPolicy h1 {
    color: rgba(182, 129, 80, 255);
}
 .PrivacyPolicy h2 {
    color: #2a2a2a;
    font-family: 'Gotham', sans-serif;
}

.PrivacyPolicy ul {
    list-style-type: disc;
    margin-left: 40px;
}

.PrivacyPolicy p, .PrivacyPolicy li {
    line-height: 1.6;
    color: #333; /* Sombre pour une meilleure lisibilité */
}

.PrivacyPolicy footer {
    margin-top: 20px;
    font-style: italic;
}

.PrivacyPolicy span {
    font-weight: bold;
}

/* Ajouter d'autres styles spécifiques ici */
